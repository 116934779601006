const getSlug = (page) => {
  let slug;
  if (page) {
    if (page.parentPage !== null) {
      if (page._type === "pillar") {
        // Now that portableText has links need to add another check as it will have no parent
        if (page.parent || page.parentPage) {
          slug = `/${page.parentPage.slug.current}/${page.slug.current}`;
        } else {
          slug = `/${page.slug.current}`;
        }
      } else if (page._type === "hotel") {
        slug = `/${page.hotelParent}/${page.slug.current}`;
      } else {
        slug = `/${page.slug.current}`;
      }
    } else {
      slug = `/${page.slug.current}`;
    }
  } else if (page?.parent === "specialOffer") {
    slug = `/review/${page.slug.current}`;
  } else {
    slug = `/${page?.slug?.current}`;
  }
  return slug;
};

export { getSlug };
